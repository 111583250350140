<template>
  <Intersect @enter.once="animation1=true" @leave.once="animation1=false" :threshold="[1.0]">
    <div class="height-div " >
      <transition name="slide-fade">
          <div id="conductores" class="offset  bg-degradado-green  pt-16 border-extra" v-if="animation1">
              <p class="title-2 margin-right-left-1" style="text-align:center">Conductores</p>
                <section class="flex-container   ">
                      
                    <article class="flex-item">
                  
                                  
                  <p class="subtitle-4 margin-right-left-1">¡Maneja <span class="green-weight">verde</span>, siéntete orgulloso y forma parte del <span class="green-weight">equipo Yipi</span>!</p>
          
                <div class="center-img">
                  <picture >
                          <source media="(max-width: 700px)" srcset="../assets/animacion/09/Yipi_AnimacionWeb_09_Ipad.gif">
                          <img class="img-small " src="../assets/animacion/09/Yipi_AnimacionWeb_09.gif" alt="animation yipi">
                  </picture>
                </div>

                      </article>
                      <article class="flex-item ">
                          <DialogInfo
                          :dialog="dialog"
                          :setDialog="setDialog"
                          />
                          <FormConductor
                          :fields="fields"
                          @setData="registrarConductor()"
                          contenedorTitle="center-margin"
                          sizeClass="form-test"
                          titleClass="title-form"
                          title="¿Quieres conducir con nosotros?"
                          subtitle="Déjanos tus datos y en breve nos pondremos en contacto contigo."
                          />
                      </article>
                  </section>
          </div>
      </transition>
    </div>
  </Intersect>
</template>
<style scoped>

  .center-img{
 
    display: flex;
    justify-content: center;
    align-items: center;
  }
</style>
<script>
  import Intersect from 'vue-intersect'
import FormConductor from "../components/commons/Form.vue";
import DialogInfo from "../components/commons/DialogInfo.vue";
import {postApiResource} from '../api/requestApi';
import {
  emailValidation,
  nombreValidation,
  telefonoValidation,
  apellidosValidation,
} from "@/utils/validation";
export default{
        data: () => ({
          animation1:false,
  checkbox: false,
    valid: true,
    dialog: false,
    inversionistaData: {
      nombre: "",
      apellidos: "",
      telefono: "",
      correo: "",
      giro: "",
    },
    fields: [
      {
        cols: "6",
        label: "Nombre",
        rules: nombreValidation,
        vmodel: "",
        index: 1,
      },
      {
        cols: "6",
        label: "Apellidos",
        rules: apellidosValidation,
        vmodel: "",
        index: 2,
      },
      {
        cols: "12",
        label: "Teléfono",
        rules: telefonoValidation,
        icon: "$telefono",
        vmodel: "",
        index: 3,
      },
      {
        cols: "12",
        label: "Email",
        rules: emailValidation,
        icon: "$email",
        vmodel: "",
        index: 4,
      },
    ],
}),
    components: {
    DialogInfo,
    FormConductor,
    Intersect
    },
    methods:{
    async registrarConductor() {
        const conductorData= {
        nombre: this.fields[0].vmodel,
        apellidos: this.fields[1].vmodel,
        telefono: this.fields[2].vmodel,
        correo: this.fields[3].vmodel,
        };
      
        const {data} = await postApiResource("/request-page/conductor",conductorData)
        if (data.statusCode == 200)
             this.dialog=true;
        
 
            
      
    },
    setDialog() {
      this.dialog = !this.dialog;
    },
    setCheckbox(value) {
      this.checkbox = value;
    },
    }
}
 
</script>
